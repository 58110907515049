import React, {
  useMemo,
} from 'react';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import Controller from 'core/components/FormController';
import {
  AddressDTO,
  ClaimInsuranceTypeSettingDTO,
  PaperPayerEntryDTO,
  PayerEntryConfigDTO,
} from 'dtos';
import {
  getSubmitButtonText,
  notEmptyString,
} from 'utils/misc';
import Button from 'pages/Dashboard/components/Button';
import {
  settingsEventChannel,
} from 'pages/Dashboard/services/eventChannels';
import DisabledFormTooltip from 'core/components/DisabledFormTooltip';
import Tooltip from 'core/components/Tooltip';
import PinIcon from 'core/icons/pin';
import Wrapper from 'pages/Dashboard/components/Input/Wrapper';
import {
  useUpsertPaperPayerConfig,
} from 'views/EMR/Settings/hooks';
import Input from 'pages/Dashboard/components/Input';
import AddressFields from 'pages/Dashboard/components/AddressFields';
import Select from 'core/components/Select';
import {
  useClaimSettings,
} from 'pages/Dashboard/pages/Billing/hooks';
import {
  validatePhoneIfSet,
} from 'pages/Dashboard/utils/helper';
import PhoneInput from 'core/components/PhoneInput';
import {
  normalizePaperPayerFormData,
} from 'views/EMR/Settings/helper';
import values from 'lodash/values';
import pick from 'lodash/pick';
import {
  requiredAddressFields,
  validateZipCode,
} from 'utils/react-hook-form';
import {
  useNotify,
} from 'core/hooks';

type Props = {
  data?: PayerEntryConfigDTO;
  onClose: () => void;
};

export const paperPayerKeys = [
  'payerName',
  'address',
  'payerId',
  'payerEntryId',
  'phoneNumber',
  'faxNumber',
] as const;
type PaperPayerFields = typeof paperPayerKeys[number];
type Form = Pick<PaperPayerEntryDTO, PaperPayerFields> & {
  insuranceType?: ClaimInsuranceTypeSettingDTO | null;
  isPinned?: boolean;
};

const defaultValues = {
  payerName: '',
  isPinned: false,
};

function validateAddressInfo(
  address?: Pick<AddressDTO, 'street' | 'unit' | 'city' | 'state' | 'zipCode'>,
) {
  return (values(pick(address, Array.from(requiredAddressFields))).every(notEmptyString)
    && validateZipCode(address?.zipCode)) || 'Address is required';
}

export default function PaperPayerForm({
  data,
  onClose,
}: Props) {
  const isNew = isNil(data);

  const { mutateAsync: upsertPaperPayerConfig } = useUpsertPaperPayerConfig();
  const { claimInsuranceTypeSettings } = useClaimSettings();
  const notify = useNotify();

  const formProps = useForm<Form>({
    mode: 'onChange',
    defaultValues: isNil(data)
      ? defaultValues
      : normalizePaperPayerFormData(data, claimInsuranceTypeSettings ?? []),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isSubmitting },
  } = formProps;

  const onSubmit = async (formData: Form) => {
    try {
      await upsertPaperPayerConfig({
        ...formData,
        insuranceType: formData.insuranceType?.insuranceType,
      });
      notify({
        message: `Payer has been ${isNew ? 'created' : 'updated'}.`,
        variant: 'success',
      });
      settingsEventChannel.emit('onPayerConfigChange');
      onClose?.();
    } finally {
      reset(formData, { keepIsSubmitted: false });
    }
  };

  const submitButtonText = useMemo(() => getSubmitButtonText(
    isNew,
    isSubmitting,
  ), [isNew, isSubmitting]);

  return (
    <FormProvider {...formProps}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 items-end w-full">
            <Controller
              name="payerName"
              control={control}
              rules={{ required: 'Payer is required' }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  required
                  error={invalid}
                  showErrorText={false}
                  id="payer-name"
                  label="Name"
                  type="text"
                  className="flex-1"
                />
              )}
            />
            <Controller
              name="isPinned"
              control={control}
              render={({ field: { value: isPinned, onChange } }) => (
                <Tooltip title={isPinned ? 'Un-Pin Payer' : 'Pin Payer'}>
                  <IconButton
                    size="small"
                    color="info"
                    className={clsx({
                      '!text-blue-500': isPinned,
                      '!text-gray-400': !isPinned,
                    })}
                    onClick={() => { onChange(!isPinned); }}
                  >
                    <PinIcon
                      className={clsx('!w-5 !h-5 !text-inherit', {
                        'rotate-45': !isPinned,
                      })}
                    />
                  </IconButton>
                </Tooltip>
              )}
            />
          </div>
          <div className="flex w-full gap-4">
            <Controller
              control={control}
              name="insuranceType"
              rules={{ required: 'Payer Type is required' }}
              render={({ field, fieldState: { invalid } }) => (
                <Select<ClaimInsuranceTypeSettingDTO>
                  {...field}
                  error={invalid}
                  showErrorText={false}
                  id="insurance-type"
                  className="w-1/2 text-gray-500 text-xs"
                  label="Type"
                  placeholder="Select"
                  options={claimInsuranceTypeSettings ?? []}
                  getLabel={(option) => (
                    `${option?.code} - ${option?.insuranceType ?? ''}`
                  )}
                  getValue={(option) => option?.code ?? ''}
                  required
                />
              )}
            />
            <Controller
              name="payerId"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  showErrorText={false}
                  id="payer-id"
                  type="text"
                  className="flex-1"
                  value={field.value ?? ''}
                  label="Payer ID"
                />
              )}
            />
          </div>
          <div className="flex w-full gap-4">
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ validate: validatePhoneIfSet }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <Wrapper
                  id="payer-phone-number"
                  label="Phone"
                  labelClasses="font-semibold text-xs text-gray-500"
                  className="w-1/2"
                  error={!isNil(error)}
                  showErrorText={false}
                >
                  <PhoneInput
                    {...field}
                    error={!isNil(error)}
                    onChange={onChange}
                    id="payer-phone-number"
                    name="payer-phone-number"
                  />
                </Wrapper>
              )}
            />
            <Controller
              name="faxNumber"
              control={control}
              rules={{ validate: validatePhoneIfSet }}
              render={({ field: { onChange, ...field }, fieldState: { invalid } }) => (
                <Wrapper
                  id="payer-fax-number"
                  label="Fax Number"
                  labelClasses="font-semibold text-xs text-gray-500"
                  className="w-1/2"
                  error={invalid}
                  showErrorText={false}
                >
                  <PhoneInput
                    {...field}
                    error={invalid}
                    onChange={onChange}
                    id="payer-fax-number"
                    name="payer-fax-number"
                  />
                </Wrapper>
              )}
            />
          </div>
          <div className="flex w-full gap-4 flex-col">
            <Controller
              control={control}
              rules={{ validate: validateAddressInfo, required: 'Address is required' }}
              name="address"
              render={({ field: { value, onChange, ...field }, fieldState: { invalid } }) => (
                <>
                  <AddressFields
                    {...field}
                    required
                    value={value}
                    onChange={(newAddress) => {
                      onChange({
                        ...newAddress,
                        attention: value?.attention,
                      });
                    }}
                    error={invalid}
                  />
                  <Input
                    {...field}
                    id="payer-address-attention"
                    type="text"
                    className="flex-1"
                    placeholder="Attention"
                    label="Attention"
                    value={value?.attention ?? ''}
                    onChange={(newValue) => {
                      onChange({
                        ...value,
                        attention: newValue,
                      });
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <Button
            variant="outlined"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Close
          </Button>
          <DisabledFormTooltip>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isSubmitting}
            >
              {submitButtonText}
            </Button>
          </DisabledFormTooltip>
        </div>
      </div>
    </FormProvider>
  );
}
