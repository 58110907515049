import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  isValidElement,
  useEffect,
  KeyboardEvent,
  useMemo,
  FocusEventHandler,
  FocusEvent,
  KeyboardEventHandler,
  RefObject,
} from 'react';
import clsx from 'clsx';
import Label from 'pages/Dashboard/components/Label';
import useSpeechToText, {
  VoiceRecognitionData,
} from 'pages/Dashboard/hooks/useSpeechToText';
import isFunction from 'lodash/isFunction';
import Mic from 'pages/Dashboard/components/Mic';
import {
  notEmptyString,
} from 'utils/misc';

type Part = 'label' | 'container' | 'root';
type DictationElement = HTMLInputElement | HTMLTextAreaElement;
type ElementWithKeyDownProps = {
  onKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
  onBlur: FocusEventHandler<DictationElement>;
};

export type Props = {
  className?: string;
  labelClasses?: string;
  micClassName?: string;
  containerClassName?: string;
  id: string;
  label?: string | ReactNode;
  onChange?: (text: string) => void;
  onUnprocessedTextChange?: (text: string) => void;
  required?: boolean;
  allowSpeechToText?: boolean;
  children?: ReactNode;
  error?: boolean;
  showErrorText?: boolean;
  trackingLabel?: string;
  speechToText?: (props: VoiceRecognitionData) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
  classes?: Partial<Record<Part, string>>;
  wrapperRef?: RefObject<HTMLDivElement>;
};

export default function Wrapper({
  className,
  micClassName,
  labelClasses,
  containerClassName,
  id,
  label,
  onChange,
  onUnprocessedTextChange,
  required,
  allowSpeechToText,
  children: _children,
  error,
  showErrorText = true,
  trackingLabel,
  speechToText,
  onBlur,
  onKeyDown: _onKeyDown,
  classes,
  wrapperRef,
}: Props) {
  const {
    unprocessedText,
    text,
    loading,
    listening,
    startVoiceRecognition,
    stopVoiceRecognition,
  } = useSpeechToText({ trackingLabel });
  const hasSpeechToText = isFunction(speechToText);

  useEffect(() => {
    if (hasSpeechToText) {
      speechToText({
        unprocessedText,
        text,
        loading,
        listening,
        startVoiceRecognition,
        stopVoiceRecognition,
      });
    }
  }, [unprocessedText, text, loading, listening]);

  const micClasses = clsx('mic-btn top-0 right-0 !z-[20]', micClassName);

  const handleClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (listening) {
      stopVoiceRecognition();
    } else {
      startVoiceRecognition();
    }
  };

  const hasDictation = useMemo(
    () => allowSpeechToText || hasSpeechToText,
    [allowSpeechToText, hasSpeechToText],
  );

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (hasDictation && e.altKey && e.code === 'KeyT') {
      e.preventDefault();
      e.stopPropagation();
      handleClick();
    }
  };

  const dictationAttributes = useMemo(() => (
    hasDictation
      ? {
        onKeyDown: (e: KeyboardEvent<HTMLElement>) => {
          _onKeyDown?.(e);
          onKeyDown(e);
        },
        onBlur: (e: FocusEvent<DictationElement>) => {
          onBlur?.(e);
          stopVoiceRecognition();
        },
      } : {}
  ), [hasDictation]);

  const children = React.Children.map<ReactNode, ReactElement<ElementWithKeyDownProps>>(
    _children as ReactElement<ElementWithKeyDownProps>[],
    (child) => (!isValidElement(child)
      ? child
      : React.cloneElement(child as ReactElement<ElementWithKeyDownProps>, dictationAttributes)),
  );

  useEffect(() => {
    if (notEmptyString(text)) { onChange?.(text); }
  }, [text]);

  useEffect(() => {
    if (typeof onUnprocessedTextChange === 'function') {
      onUnprocessedTextChange(unprocessedText);
    }
  }, [unprocessedText]);

  const containerClasses = clsx(
    'w-full relative flex',
    containerClassName,
  );

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'flex flex-col space-y-0.5 relative',
        className,
        classes?.root,
        { 'has-error': error },
      )}
    >
      {typeof label === 'string' ? (
        <Label
          className={clsx(labelClasses, classes?.label)}
          id={id}
          label={label}
          required={required}
        />
      ) : label}
      {error && showErrorText && (
        <span className="absolute right-0 !text-xs text-red-500 font-semibold -top-1">
          Invalid entry
        </span>
      )}
      <div className={clsx(containerClasses, classes?.container)}>
        {children}
        {allowSpeechToText && (
          <Mic
            micClasses={micClasses}
            listening={listening}
            loading={loading}
            onFocus={startVoiceRecognition}
            onBlur={stopVoiceRecognition}
            onClick={handleClick}
          />
        )}
      </div>
    </div>
  );
}
