import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import {
  getApiSettingsGetAppointmentSettings,
  getApiSettingsGetChargeFilterSettings,
  getApiSettingsGetInsuranceSettings,
  getApiSettingsGetMediaSettings,
  getApiSettingsGetUserManagementSettings,
  getApiSettingsGetWriteOffSettings,
} from 'endpoints';
import {
  useGetUserSettings,
} from 'pages/Dashboard/hooks/useUserSettings';
import {
  InsuranceSettingsDTO,
  MediaSettingsDTO,
  SettingsDTO,
  UserManagementSettingsDTO,
  UserSettingDTO,
} from 'dtos';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  getToDoSettings,
} from 'pages/Dashboard/pages/Todos/transport';
import {
  getUserSettings,
} from 'pages/Dashboard/services/api';
import {
  getClaimSettings,
} from 'pages/Dashboard/pages/Billing/api';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import dayjs from 'utils/dayjs';
import {
  useGetAppointmentTypes,
  useGetPatientAppointmentStatusSettings,
} from 'views/EMR/Settings/hooks';
import {
  useDatadogRUM,
} from 'core/hooks';
import {
  PrefetchedDataProps,
} from 'pages/Dashboard/types/common';

type Props = {
  settings: Record<string, any>;
  setSettings: (settings: Record<string, any>) => void;
  retrievingSettings: boolean;
};

export const UserSettingsContext = createContext<Props>({
  settings: {},
  setSettings: () => {},
  retrievingSettings: false,
});

const metaList: PrefetchedDataProps<Partial<
  SettingsDTO
  & UserSettingDTO
  & UserManagementSettingsDTO
  & MediaSettingsDTO
  & InsuranceSettingsDTO
>>[] = [{
  key: 'user-view-settings',
  fetchFn: getUserSettings,
}, {
  key: 'charge-filter-settings',
  fetchFn: getApiSettingsGetChargeFilterSettings,
}, {
  key: 'appointment-settings',
  fetchFn: getApiSettingsGetAppointmentSettings,
}, {
  key: 'to-do-settings',
  fetchFn: getToDoSettings,
}, {
  key: 'claim-settings',
  fetchFn: getClaimSettings,
}, {
  key: 'payment-metadata',
  fetchFn: getApiSettingsGetWriteOffSettings,
}, {
  key: 'user-settings',
  fetchFn: getApiSettingsGetUserManagementSettings,
}, {
  key: 'media-settings',
  fetchFn: getApiSettingsGetMediaSettings,
}, {
  key: 'insurance-meta',
  fetchFn: getApiSettingsGetInsuranceSettings,
}, {
  key: 'user-settings',
  fetchFn: getApiSettingsGetUserManagementSettings,
}];

export default function UserSettingsProvider({ children }: PropsWithChildren) {
  const userInfo = useUserInfo();
  const initDatadogRUM = useDatadogRUM();

  const { data, isLoading: loadingUserSettings } = useGetUserSettings();

  const { isLoading: loadingAppointmentSettings } = useGetAppointmentTypes();
  const { isLoading: loadingPatientAppointmentStatuses } = useGetPatientAppointmentStatusSettings();

  const loadingMetaDataStates = metaList.map((metaParams) => usePrefetchedData(metaParams));

  const loadingList = [
    loadingUserSettings,
    loadingAppointmentSettings,
    loadingPatientAppointmentStatuses,
    ...map(loadingMetaDataStates, 'isLoading'),
  ];

  const loading = useMemo(() => (
    loadingList.some((isLoading) => isLoading) || isNil(userInfo)
  ), [...loadingList, userInfo]);

  const [settings, setSettings] = useState<Record<string, any>>({});
  const value = useMemo(() => ({
    settings,
    setSettings,
    retrievingSettings: loading,
  }), [settings, setSettings, loading]);

  useEffect(() => {
    if (!isEmpty(data) && !loadingUserSettings) {
      const { timeZoneId } = userInfo?.organization ?? {};
      dayjs.tz.setDefault(timeZoneId ?? undefined);
      setSettings(JSON.parse(data?.preferenceJson ?? '{}'));
      initDatadogRUM();
    }
  }, [data, loadingUserSettings, loading]);

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  );
}
