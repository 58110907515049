import {
  DependencyList,
  useContext,
  useEffect,
} from 'react';
import isNil from 'lodash/isNil';
import {
  SignalRHubContext,
} from 'core/context/signalRHub';

const hubEvents = [
  'OnEncounterUpdated',
  'OnUnreadToDoCountUpdated',
  'OnToDoUpdated',
  'OnToDoCommentAdded',
  'OnTerminalPaymentUpdated',
  'OnReportCompleted',
  'OnBulkPatientStatementCompleted',
  'OnStickyUpdated',
  'OnInvoiceUpdated',
  'OnBulkPaperClaimsCompleted',
] as const;

type HubEvent = typeof hubEvents[number];

export function useSignalREffect(
  eventName: HubEvent,
  callback: (...args: any[]) => void,
  dependencies: DependencyList = [],
) {
  const { hubConnection } = useContext(SignalRHubContext);

  useEffect(() => {
    if (!isNil(hubConnection)) {
      hubConnection?.on?.(eventName, callback);
    }
    return () => {
      hubConnection?.off?.(eventName, callback);
    };
  }, [hubConnection, ...dependencies]);
}
