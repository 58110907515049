import React, {
  PropsWithChildren,
  useEffect,
} from 'react';
import FullScreenSpinner from 'core/components/FullScreenSpinner';
import {
  useAuth,
} from 'core/hooks';

export default function ProtectedRouter({ children }: PropsWithChildren) {
  const {
    issueAccessToken,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      issueAccessToken();
    } else {
      const targetUrl = `${window.location.pathname}${window.location.search}`;
      loginWithRedirect({ appState: { targetUrl } });
    }
  }, [isAuthenticated]);

  return isLoading || !isAuthenticated ? <FullScreenSpinner /> : children as JSX.Element;
}
