import React from 'react';
import Tag from 'core/components/Tag';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import isNil from 'lodash/isNil';
import clsx from 'clsx';

type Parts = 'root' | 'label' | 'icon';

type Props = {
  id?: string | null;
  label?: string;
  onRemove?: (key?: string | null) => void;
  classes?: Partial<Record<Parts, string>>;
  disabled?: boolean;
  defaultColor?: string;
};

export default function Pill({
  id,
  label,
  onRemove,
  classes,
  disabled,
  defaultColor = 'bg-gray-200',
}: Props) {
  return (
    <Tag
      key={id}
      className={clsx('flex items-center gap-1 h-4 w-fit', classes?.root)}
      tagName={label}
      defaultColor={defaultColor}
    >
      <span className={clsx('max-w-[150px] truncate', classes?.label)}>
        {label}
      </span>
      {!isNil(onRemove) && (
        <IconButton
          className="!p-0 !text-inherit"
          disabled={disabled}
          onClick={(event) => {
            event.stopPropagation();
            onRemove?.(id);
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <ClearIcon className={clsx('!w-4 !h-4 !text-inherit', classes?.icon)} />
        </IconButton>
      )}
    </Tag>
  );
}
