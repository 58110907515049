import React, {
  useEffect,
} from 'react';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import MuiAlert, {
  AlertProps,
} from '@mui/material/Alert';

export type AlertVariant = AlertProps['severity'];

export type Props = Pick<AlertProps, 'className'
  | 'variant'
  | 'severity'
  | 'children'
  | 'icon'
  | 'classes'
> & {
  onClose?: () => void;
  open?: boolean;
  dismissible?: boolean;
  timeout?: number;
};

export default function Alert({
  className,
  variant = 'standard',
  severity,
  children,
  open,
  timeout = 10000,
  dismissible,
  onClose = noop,
  icon,
  classes,
}: Props) {
  useEffect(() => {
    const timeoutId = dismissible && open
      ? setTimeout(() => { onClose?.(); }, timeout)
      : null;

    return () => {
      if (!isNil(timeoutId)) { clearTimeout(timeoutId); }
    };
  }, [open, timeout, dismissible]);

  return open ? (
    <MuiAlert
      className={className}
      variant={variant}
      severity={severity}
      onClose={dismissible ? onClose : undefined}
      icon={icon}
      classes={classes}
    >
      {children}
    </MuiAlert>
  ) : null;
}
