import {
  getApiSettingsGetAppointmentTypeSettings,
  getApiSettingsGetPayerEntryConfigsByType,
  getApiTemplateGetFieldList,
  getApiTemplateGetMasterTemplateList,
  getApiTemplateGetSectionTemplateList,
  getApiUserGetUsers,
  getApiTagGetTags,
  postApiOrganizationGetAllConsentFormsByOrg,
} from 'endpoints';
import {
  PayerEntryConfigDTO,
  TemplateFieldDTO,
  TemplateListItemDTO,
} from 'dtos';
import {
  normalizeAppointmentTypes,
  normalizeAppointmentTypeSettings,
} from 'views/EMR/Settings/helper';
import {
  GetQueryParams,
} from 'pages/Dashboard/types/common';
import {
  AppointmentTypes,
  AppointmentTypeSettings,
} from 'views/EMR/Settings/Appointments/types';
import {
  UserView,
} from 'views/EMR/Settings/Users';

export async function getAppointmentTypeSettings(): Promise<AppointmentTypeSettings[]> {
  const data = await getApiSettingsGetAppointmentTypeSettings();
  return normalizeAppointmentTypeSettings(data);
}

export async function getAppointmentTypes(): Promise<AppointmentTypes> {
  const data = await getApiSettingsGetAppointmentTypeSettings();
  return normalizeAppointmentTypes(data);
}

export async function getAllConsentForms({
  searchQuery,
  setTotalSize,
  pagination,
}: GetQueryParams) {
  const response = await postApiOrganizationGetAllConsentFormsByOrg({
    consentFormTitleText: searchQuery,
    pageRequest: {
      page: pagination?.pageIndex ?? 0,
      pageSize: pagination?.pageSize ?? 25,
    },
  });
  setTotalSize?.(response?.consentForms?.pageProperties?.totalSize ?? 0);
  return response?.consentForms?.data ?? [];
}

export async function getPayerConfigurations({
  searchQuery: searchText,
  setTotalSize,
  pagination,
  configType,
}: GetQueryParams): Promise<PayerEntryConfigDTO[]> {
  const response = await getApiSettingsGetPayerEntryConfigsByType({
    searchText,
    configType,
    ...pagination,
  });
  setTotalSize(response?.pageProperties?.totalSize ?? 0);

  return response.data ?? [];
}

export async function getMasterTemplates({
  setTotalSize,
  searchQuery: searchText,
  pagination,
}: GetQueryParams): Promise<TemplateListItemDTO[]> {
  const response = await getApiTemplateGetMasterTemplateList({ searchText, ...pagination });
  setTotalSize(response?.pageProperties?.totalSize ?? 0);
  return response?.data ?? [];
}

export async function getSectionTemplates({
  setTotalSize,
  searchQuery: searchText,
  pagination,
}: GetQueryParams): Promise<TemplateListItemDTO[]> {
  const response = await getApiTemplateGetSectionTemplateList({ searchText, ...pagination });
  setTotalSize(response?.pageProperties?.totalSize ?? 0);
  return response?.data ?? [];
}

export async function getFields({
  setTotalSize,
  searchQuery: searchText,
  pagination,
}: GetQueryParams): Promise<TemplateFieldDTO[]> {
  const response = await getApiTemplateGetFieldList({ searchText, ...pagination });
  setTotalSize(response?.pageProperties?.totalSize ?? 0);
  return response?.data ?? [];
}

export async function getUsers(): Promise<UserView[]> {
  const response = await getApiUserGetUsers();
  return response?.map(({ title, phoneNumber, ...user }) => (
    { userTitle: { title: title ?? '', isRestricted: false }, phoneNumber: phoneNumber?.phoneNumberWithAreaCode, ...user }
  )) ?? [];
}

export async function getTags({
  searchQuery,
  setTotalSize,
  pagination,
}: GetQueryParams) {
  const response = await getApiTagGetTags({
    search: searchQuery,
    Page: pagination?.pageIndex ?? 0,
    PageSize: pagination?.pageSize ?? 25,
  });
  setTotalSize?.(response?.pageProperties?.totalSize ?? 0);
  return response?.data ?? [];
}
