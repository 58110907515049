import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import {
  NPISearchItemDTO,
  ProviderDTO,
  UserDTO,
} from 'dtos';
import {
  getReferringProviderFromLastVisit,
} from 'pages/Dashboard/services/api';
import {
  getProvidersByParams,
} from 'pages/Dashboard/pages/Billing/api';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import {
  getApiUserGetAllUsersByTitleTitle,
} from 'endpoints';
import {
  normalizeUsers,
} from 'pages/Dashboard/utils/helper';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

export function useGetUsers(onlyDoctors = true): UseQueryResult<UserDTO[], Error> {
  const { user } = useUserInfo() ?? {};
  const doctor = onlyDoctors ? 'doctor' : '';
  return usePrefetchedData<UserDTO[]>({
    key: `doctorList/${doctor}`,
    fetchFn: async () => {
      const providers = await getApiUserGetAllUsersByTitleTitle(doctor);
      return normalizeUsers(providers, user?.userId);
    },
  });
}

export function useProvidersSearch(
  queryParams: ProviderDTO | null,
  enabled: boolean = true,
): UseQueryResult<NPISearchItemDTO[], Error> {
  return useQuery<NPISearchItemDTO[], Error>(
    ['providers-search', values(queryParams)],
    async () => {
      const response = await (isNil(queryParams)
        ? Promise.resolve({ result: [] })
        : getProvidersByParams(queryParams)
      );

      return response?.result ?? [];
    },
    { enabled: !isNil(queryParams) && !isEmpty(queryParams) && enabled },
  );
}

export function useReferringProviderFromLastVisit(
  patientId: number = 0,
): UseQueryResult<ProviderDTO | null, Error> {
  return useQuery<ProviderDTO | null, Error>(
    ['last-visit-rp', patientId],
    async () => {
      const result = await getReferringProviderFromLastVisit(patientId);
      return result instanceof Object ? result : null;
    },
    { enabled: patientId > 0 },
  );
}
