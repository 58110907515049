import {
  ClaimTransmissionDTO,
  ConsentFormInfoDTO,
  FaxContactDTO,
  InsuranceDTO,
  InsurancePaymentDTO,
  MultiMediaResponse,
  TemplateListItemDTO,
  VisitNoteMipsDTO,
} from 'dtos';
import createEventBus from 'core/EventBus';
import {
  Claim,
  Payment,
  PaymentStatus,
  ServiceAllocation,
} from 'pages/Dashboard/pages/Billing/types';
import {
  Entity,
  SidePanelProps,
} from 'pages/Dashboard/contexts/sidePanel';
import {
  InsuranceDialogParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/Details/InsuranceDialog';
import {
  AppointmentConfirmationParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/AppointmentConfirmation';
import {
  CreateAppointmentDialogParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/CreateAppointmentDialog';
import {
  ToDoDialogParams,
} from 'pages/Dashboard/pages/Todos/CreateToDoForm';
import {
  DialogParams as PaymentDialogParams,
} from 'views/EMR/Billing/PaymentDialog';
import {
  EOBDialogParams,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog';
import {
  EOBAdjustmentCodeParams,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/EOBAdjustmentCodePopover';
import {
  ChargePolicyDialogParams,
} from 'pages/Dashboard/pages/Billing/ChargeMaster/ChargePolicyDialog';
import {
  ViewName as PaymentViewName,
} from 'pages/Dashboard/pages/Billing/Payments';
import {
  PatientCompact,
} from 'pages/Dashboard/pages/Encounters/types/patient';
import {
  PatientDialogParams,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/CreatePatientDialog';
import {
  AppointmentTypeDialogParams,
} from 'views/EMR/Settings/Appointments/Dialog';
import {
  SecondaryClaimDialogParams,
} from 'pages/Dashboard/pages/Billing/ClaimForm/SecondaryClaimDialog';
import {
  PayerConfigDialogParams,
} from 'views/EMR/Settings/Payers/Dialog';
import {
  VisitNoteParams as VisitConfigParams,
} from 'views/EMR/Settings/VisitNotes';
import {
  CreateDialogParams,
} from 'views/EMR/Settings/Forms';
import {
  MedicalBackgroundParams,
} from 'views/EMR/components/MedicalBackgroundDialog';
import {
  FieldDialogParams,
} from 'views/EMR/Settings/VisitNotes/FieldDialog';
import {
  AddendumDialogProps,
} from 'pages/Dashboard/pages/Charts/pages/Details/components/VisitsAndNotes/AddendumDialog';
import {
  ServiceType,
} from 'views/EMR/components/ServiceLookup/type';
import {
  RequestMultiMediaDelete,
} from 'pages/Dashboard/hooks/useDeleteMediaHandler';
import {
  WriteOffDialogProps,
} from 'pages/Dashboard/pages/Billing/Charges/WriteOffDialog';
import {
  SidePanelType,
} from 'pages/Dashboard/pages/Billing/Charges/ChargesSidePanel';
import {
  PCPDialogParams,
} from 'views/EMR/components/PCPDialog';
import {
  FaxDialogParams,
} from 'pages/Dashboard/components/SendFaxDialog';
import {
  DialogParams as EncountersAllocationDialogParams,
} from 'views/EMR/Billing/EncountersAllocation';
import {
  StatementDialogParams,
} from 'pages/Dashboard/pages/Ledger/StatementFormDialog';
import {
  PurchaseDialogParams,
} from 'views/EMR/Billing/CreatePurchaseDialog';
import {
  HealthRemarkCodeParams,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/RemarkCodesPopover';
import {
  EOBServiceCodeCommentParams,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/EOBServiceCodeCell';

type MediaEventChannel = {
  onMediaDeleted: (mediaPayload: RequestMultiMediaDelete) => void;
  onMediaUpdated: () => void;
  onMediaSignatureDialogOpen: (patientMultimedia: MultiMediaResponse) => void;
};
export const mediaEventChannel = createEventBus<MediaEventChannel>();

type InsurancesEventChannel = {
  openInsuranceDialog: (params: InsuranceDialogParams) => void;
  onInsuranceCorrection: (insurance: InsuranceDTO, patientId: number) => void;
  onEligibilityCheckRefresh: () => void;
  onAddInsurance: () => void;
};

export const insurancesEventChannel = createEventBus<InsurancesEventChannel>();

type AppointmentsEventChannel = {
  onAppointmentConfirmation: (params: AppointmentConfirmationParams) => void;
  onAppointmentInsuranceChange: (
    insurance: InsuranceDTO,
    encounterId?: number,
    metaKey?: string,
  ) => void;
  onEventCreate: (params: CreateAppointmentDialogParams) => void;
  onEventCreated: (created: boolean, id?: number) => void;
  onEventUpdated: (id?: number, forceRerender?: boolean) => void;
  onEventDeleted: (id?: number) => void;
  onEventDetails: (entity: Entity, prevPanelProps?: SidePanelProps) => void;
};

export const appointmentsEventChannel = createEventBus<AppointmentsEventChannel>();

export type BillingEventChannel = {
  // charges
  onChargesInfoChange: (entity: Partial<Entity>) => void;
  onChargesRowToggle: (encounterId: number, expand?: boolean) => void;
  onChargesPanelOpen: (entity: Entity, type: SidePanelType) => void;
  // prices
  onPriceDialogOpen: (billingChargeInfo: ChargePolicyDialogParams) => void;
  onPricesChanged: () => void;
  // eob
  onRelatedAllocation: (props: [HTMLElement | null, ServiceAllocation[]]) => void;
  onEncountersAllocation: (params: EncountersAllocationDialogParams) => void;
  onMarkAsSecondary: (claimId: number, onComplete?: () => void) => void;
  // purchase
  onPurchaseCreate: (params: PurchaseDialogParams) => void;
  onOpenStatementHistory: (patientId: number) => void;
};
export const billingEventChannel = createEventBus<BillingEventChannel>();

type ClaimEventChannel = {
  onClaimCreating: (encounterId: number) => void;
  onClaimSplit: (claim: Claim) => void;
  onClaimSplitOpen: (claim: Claim) => void;
  onSecondaryClaimCreateOpen: (params: SecondaryClaimDialogParams) => void;
  onClaimTransmissionsOpen: (transmissions: ClaimTransmissionDTO[]) => void;
};
export const claimEventChannel = createEventBus<ClaimEventChannel>();

export type PaymentsEventChannel = {
  onPaymentDialogOpen: (params: PaymentDialogParams | null) => void;
  onEOBDialogOpen: (params: EOBDialogParams | null) => void;
  onPaymentsDataChanged: (type: PaymentViewName) => void;
  onPaymentPrint: (payment: Payment, onComplete?: (isCompleted: boolean) => void) => void;
  onPaymentDelete: (payment: Payment, onComplete?: () => void) => void;
  onSquarePaymentUpdate: (paymentStatus: PaymentStatus) => void;
  onPatientBalanceChange: (paymentStatus?: PaymentStatus) => void;
  onChangeEOBAdjustmentCode: (params: EOBAdjustmentCodeParams) => void;
  onChangeEOBRemarkCodes: (params: HealthRemarkCodeParams) => void;
  onChangeEOBServiceCodeComment: (params: EOBServiceCodeCommentParams) => void;
  onEOBAllocationDelete: (claimId: number, id?: number) => void;
  onInsurancePaymentDialogOpen: (insurancePayment: InsurancePaymentDTO) => void;
  onAddWriteoff: (params: WriteOffDialogProps | null) => void;
  onStatementDialog: (params: StatementDialogParams) => void;
};

export const paymentsEventChannel = createEventBus<PaymentsEventChannel>();

type TodosEventChannel = {
  onToDosChanged: (toDoId?: number, patientId?: number) => void;
  onOpenToDo: (toDoId?: number, prevPanelProps?: SidePanelProps) => void;
  onCreateToDo: (params: Omit<ToDoDialogParams, 'onClose'> | null) => void;
};

export const toDosEventChannel = createEventBus<TodosEventChannel>();

type PatientEventChannel = {
  onCreatePatientDialog: (params: PatientDialogParams) => void;
  onPatientCreated: (patient: PatientCompact) => void;
  onPatientConsentFormPrint: (id: string) => void;
  onEditMedicalBackground: (params: MedicalBackgroundParams) => void;
  onMedicalBackgroundChange: (patientId: number, type?: string) => void;
  onEditPCP: (params: PCPDialogParams) => void;
  onPanelExtra: (expanded?: boolean) => void;
};

export const patientEventChannel = createEventBus<PatientEventChannel>();

type FaxEventChannel = {
  onFaxChange: () => void;
  onUpdateFaxDetails: (id: number) => void;
  onOpenContacts: (contact?: FaxContactDTO) => void;
  onContactChange: () => void;
  onOpenSendFax: (params?: Partial<FaxDialogParams>) => void;
};

export const faxEventChannel = createEventBus<FaxEventChannel>();

export type VisitNoteParams = {
  patientId: number;
  encounterId: number;
} & Record<string, unknown>;

type VisitNoteEventChannel = {
  onVisitNoteFinalized: () => void;
  onTemplateDelete: (template: TemplateListItemDTO) => void;
  onSaveAsMasterTemplate: () => void;
  onServiceCodesUpdate: (type: ServiceType) => void;
  onMIPSUpdate: (mipsList: VisitNoteMipsDTO[]) => void;
  onAddAddendum: (data: AddendumDialogProps) => void;
  onAddendumPosted: (encounterId?: number) => void;
  onRelatedInfoUpdate: (params: Partial<VisitNoteParams>) => void;
};

export const visitNoteEventChannel = createEventBus<VisitNoteEventChannel>();

type ReportEventChannel = {
  onReportCreate: () => void;
  onReportChange: () => void;
};

export const reportEventChannel = createEventBus<ReportEventChannel>();

type SettingsEventChannel = {
  onChangeTemplates: () => void;
  onCreateTemplate: (params: CreateDialogParams) => void;
  onEditTemplate: (params: ConsentFormInfoDTO) => void;
  onCreateAppointmentType: (params: AppointmentTypeDialogParams) => void;
  onAppointmentTypesChange: () => void;
  onPayerConfigDialog: (params: PayerConfigDialogParams) => void;
  onPayerConfigChange: () => void;
  onRoomSettingsChange: () => void;
  onTemplateDetails: (type: VisitConfigParams['config']) => void;
  onVisitNoteSettingsChange: (
    type?: VisitConfigParams['config'],
    template?: Pick<TemplateListItemDTO, 'templateName' | 'sectionName' | 'userId'>,
  ) => void;
  onTemplateFieldDialog: (params?: FieldDialogParams) => void;
  onUsersChange: () => void;
  onTagsChange: () => void;
};

export const settingsEventChannel = createEventBus<SettingsEventChannel>();

type AIAssistantEventChannel = {
  onNewUserMessage: (message: string) => void;
  onShowAIAssistantDialog: () => void;
};

export const aiAssistantEventChannel = createEventBus<AIAssistantEventChannel>();
