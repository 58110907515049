import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {
  SvgIconProps,
} from '@mui/material/SvgIcon/SvgIcon';

export default function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 128 128"
      {...props}
      htmlColor="transparent"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="6"
        fill="none"
        d="M33 40H95.5M53 40V28C53 26.8954 53.8954 26 55 26H74C75.1046 26 76 26.8954 76 28V40M91 51L86.9284 94.7415C86.5455 98.8547 83.0938 102 78.9628 102L49.0372 102C44.9062 102 41.4545 98.8547 41.0716 94.7415L37 51"
      />
    </SvgIcon>
  );
}
