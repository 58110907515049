import negate from 'lodash/negate';
import isNil from 'lodash/isNil';
import unescape from 'lodash/unescape';
import forEach from 'lodash/forEach';
import {
  AddressDTO,
  MultiMediaResponse,
  PrimaryCarePhysicianDTO,
} from 'dtos';
import dayjs from 'utils/dayjs';
import {
  formatPhone,
  getAddressString,
  getIndexName,
  pluralize,
} from 'utils/string';
import head from 'lodash/head';
import compact from 'lodash/compact';
import {
  formatDate,
} from 'utils/date';
import {
  GeoCodeAddressCategory,
  GeoCodeAddressComponent,
  GeoCodeAddress,
  geoCodeFieldsMapping,
} from 'pages/Dashboard/components/AddressSuggestion';
import {
  AddressForm,
} from 'pages/Dashboard/components/AddressFields';

export { v4 as uuid } from 'uuid';

export const em = (em: number = 1, relativeSize: number = 16) => em * relativeSize;

export function blobToDataUrl(blob: Blob | File | null): Promise<string | null> {
  return isNil(blob) ? Promise.resolve(null) : new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}

export function getFileExtension(fileName: string = ''): string | null {
  const [, extension] = fileName.match(/^.*\.(\w+)$/) ?? [null, null];
  return !isNil(extension) ? extension.toLowerCase() : null;
}

export function getFileParts(filename: string): [string, string] {
  const match = filename.match(/^(.*?)(?:\.([^.\s]+))?$/);
  const [, file, extension] = match ?? [];
  return isNil(match) ? [filename, ''] : [file ?? filename, extension ?? ''];
}

export function getMediaType(media: MultiMediaResponse): 'document' | 'image' {
  return getFileExtension(media.fileName ?? '') === 'pdf' ? 'document' : 'image';
}

export function getDefaultIf(
  value: any,
  defaultValue: typeof value,
  condition: (v: typeof value) => boolean,
) {
  return condition(value) ? defaultValue : value;
}

export function setDefaultValueIf(
  object: Record<string, any>,
  defaultValue: any,
  condition: (v: any, key: string) => boolean,
) {
  const result: Record<string, any> = {};

  forEach(object, (v, k) => {
    if (condition(v, k)) {
      result[k] = defaultValue;
    }
  });

  return result;
}

export function isEmptyString(value: any) {
  return isNil(value) || (typeof value === 'string' && value.trim() === '');
}

export const notEmptyString = negate(isEmptyString);

export function unescapeHtml(content: string, currentDate: string) {
  return unescape(content).replaceAll('{Date}', `<b>${currentDate}</b>`);
}

export function getSubmitButtonText(
  isNew: boolean,
  isSubmitting: boolean,
  wordPairs = [['Create', 'Creating...'], ['Update', 'Updating...']],
) {
  return wordPairs[Number(!isNew)][Number(isSubmitting)];
}

export type DateUnit = 'year' | 'month' | 'week' | 'day';
const dateUnits: DateUnit[] = ['year', 'month', 'week', 'day'];
type AgeInformation = {
  value: number;
  unit: DateUnit;
};

export function getAge(dateOfBirth?: string, unit?: DateUnit): AgeInformation {
  let value = 0;
  let currentUnitIndex = -1;
  if (isNil(unit)) {
    while (value === 0 && currentUnitIndex < 3) {
      currentUnitIndex += 1;
      value = dayjs().diff(dateOfBirth, dateUnits[currentUnitIndex]);
    }
  } else {
    value = dayjs().diff(dateOfBirth, unit);
  }
  return {
    value,
    unit: unit ?? dateUnits[currentUnitIndex],
  };
}

export function getPCPBlurb({
  state,
  dateLastSeen,
  ...primaryCarePhysician
}: PrimaryCarePhysicianDTO, title?: string) {
  const indexName = getIndexName(primaryCarePhysician);
  const result = compact([
    isNil(title) ? indexName : `${title}: ${indexName}`,
    state,
    isEmptyString(dateLastSeen) ? null : `Last seen on ${formatDate(dateLastSeen ?? '')}`,
  ]).join(' • ');

  return isEmptyString(result) ? 'Not Set' : result;
}

export function getPatientAge(dateOfBirth?: string): string {
  const { value, unit } = getAge(dateOfBirth);
  return `${pluralize(unit, value)} old`;
}

export function isNilOrZero(value?: number | string | null) {
  const result = Number(value);
  return result === 0 || Number.isNaN(result);
}

export function getNumberFromValue(value?: number | string | null) {
  const result = Number(value ?? '0'); // null and undefined will become 0; bad string will be NaN
  return Number.isNaN(result) ? 0 : result;
}

export function formatContact(contact: string) {
  const phoneParts = contact.match(/(?:[-+() ]*\d){10,13}/g);
  const formattedPhone = formatPhone(head(phoneParts) ?? '');
  const name = contact
    .replace(/(?:[-+]*\d){10,13}/g, '')
    .replace(/(\(|\)|-)/g, '');

  return [name, formattedPhone];
}

export function roundNumber(value: number = 0, fractionDigits = 2): number {
  return Number(value.toFixed(fractionDigits));
}

export function autosuggestHighlightParse(text: string, matches: [number, number][]) {
  const result = [];
  let lastEnd = 0;

  matches.forEach(([start, end]) => {
    if (start > lastEnd) {
      result.push({ text: text.slice(lastEnd, start), highlight: false });
    }
    result.push({ text: text.slice(start, end), highlight: true });
    lastEnd = end;
  });

  if (lastEnd < text.length) {
    result.push({ text: text.slice(lastEnd), highlight: false });
  }

  return matches.length === 0 ? [{ text, highlight: false }] : result;
}

export function formatPlace(value: AddressForm) {
  return {
    place: {
      description: getAddressString(value),
      structured_formatting:
      {
        main_text: value.street ?? '',
        secondary_text: compact([value.city, value.state, value.country]).join(' '),
      },
    },
  };
}

export function getAddressComponentByType(
  components: GeoCodeAddressComponent[],
  category: GeoCodeAddressCategory,
  firstMatch = false,
  valueType: GeoCodeAddress = 'short_name',
) {
  const types = geoCodeFieldsMapping[category];
  const typesArray = Array.isArray(types) ? types : [types];
  const matchedComponents = firstMatch ? components.find((component) =>
    types.some((type) => component?.types?.includes(type)))
    : components.filter((component) =>
      typesArray.some((type) => component?.types?.includes(type)));

  return firstMatch && !isNil(matchedComponents)
    ? (matchedComponents as GeoCodeAddressComponent)?.[valueType]
    : (matchedComponents as GeoCodeAddressComponent[])?.map((component) => component[valueType]).join(' ');
}

function isSubstringMatches(
  value: string,
  toMatch?: string,
) {
  return !isEmptyString(value) && !isNil(toMatch) && new RegExp(value, 'i').test(toMatch);
}

export const getGeoCodeToAddress = (
  components: GeoCodeAddressComponent[],
  formattedAddress?: string,
): AddressDTO => {
  const unit = getAddressComponentByType(components, 'unit');
  const postalCode = getAddressComponentByType(components, 'zipCode');
  const street = getAddressComponentByType(components, 'street') ?? '';
  const locality = getAddressComponentByType(components, 'locality', true);
  const sublocality = getAddressComponentByType(components, 'sublocality', true);
  const neighborhood = getAddressComponentByType(components, 'neighborhood', false, 'long_name') ?? '';
  const country = getAddressComponentByType(components, 'country');
  const state = getAddressComponentByType(components, 'state');

  const geoCityParts = compact([locality, sublocality, neighborhood]);
  const city = geoCityParts.find((value) => isSubstringMatches(value, formattedAddress)) ?? geoCityParts.find((c) => c) ?? '';

  return {
    street,
    unit,
    city: city ?? '',
    state,
    zipCode: postalCode,
    shortZipCode: postalCode,
    country,
  };
};
